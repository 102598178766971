export default defineNuxtRouteMiddleware(async (to, from) => {
    const nuxtApp = useNuxtApp();

    if (! nuxtApp.$me.check()) {
        let loginPath = await nuxtApp.$localePath('login');
        return await navigateTo(loginPath);
    }

    if (! nuxtApp.$me.isValidated()) {
        let accountPath = await nuxtApp.$localePath('account');
        return await navigateTo(accountPath);
    }
})
